<template>
  <div class="saleContractDetail">
    <el-form class="form" label-width="auto">
      <el-form-item>
        <h1 style="margin-right: 20px;">{{ data.contract.id ? '编辑' : '新增' }}销售合同</h1>
        <el-button-group>
          <el-button @click="router.go(-1)">返回</el-button>
          <el-button type="primary" @click="add()" v-if="data.contract.id == null">保存</el-button>
          <el-button type="primary" @click="setById()" v-if="data.contract.id != null && data.contract.reviewState == 'pass'">保存</el-button>
        </el-button-group>
      </el-form-item>
      <el-form-item label="状态" v-if="data.contract.id != null">
        <el-text v-if="data.contract.reviewState == 'none'">未审核</el-text>
        <el-text type="success" v-if="data.contract.reviewState == 'pass'">通过</el-text>
        <el-text type="danger" v-if="data.contract.reviewState == 'unpass'">不通过</el-text>
      </el-form-item>
      <el-form-item label="审核备注" v-if="data.contract.id != null && data.contract.reviewState != 'none'">
        <el-text>{{ data.contract.reviewNote }}</el-text>
      </el-form-item>
      <el-form-item label="合同日期">
        <el-date-picker style="width: 100%;" v-model="data.contract.contractDate" type="date" :disabled="data.contract.id != null" />
      </el-form-item>
      <el-form-item label="资质证书">
        <el-upload style="width: 100%;" v-model:file-list="data.file.qualification" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag :on-error="() => ElMessage.error('上传失败')" :disabled="data.contract.id != null"
          :limit="10" :on-preview="(f) => util.previewElfile(f)">
          <el-text v-show="data.contract.id != null">当前禁止上传</el-text>
          <el-text v-show="data.contract.id == null">点击或拖转到此处上传文件</el-text>
        </el-upload>
      </el-form-item>
      <el-form-item label="合同草稿">
        <el-upload style="width: 100%;" v-model:file-list="data.file.contractDraft" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag :on-error="() => ElMessage.error('上传失败')" :disabled="data.contract.id != null"
          :limit="10" :on-preview="(f) => util.previewElfile(f)">
          <el-text v-show="data.contract.id != null">当前禁止上传</el-text>
          <el-text v-show="data.contract.id == null">点击或拖转到此处上传文件</el-text>
        </el-upload>
      </el-form-item>
      <template v-if="data.contract.id != null && data.contract.reviewState == 'pass'">
        <el-form-item label="合同原件">
          <el-upload style="width: 100%;" v-model:file-list="data.file.contract" :action="api.baseURL() + '/backend/file/upload'" :headers="api.tokenHeader()" multiple drag :on-error="() => ElMessage.error('上传失败')" :limit="10"
            :on-preview="(f) => util.previewElfile(f)">
            <el-text>点击或拖转到此处上传文件</el-text>
          </el-upload>
        </el-form-item>
      </template>
      <el-form-item label="备注">
          <el-input v-model="data.contract.note" :max="100" />
        </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import { reactive, } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import api from '../../apis/api'
import cache from '../../utils/cache'
import util from '../../utils/util'

const router = useRouter()
const show = reactive({
  typeList: [],
})
const pageParam = cache.getObject(cache.keys.pageParam + "saleContractDetail")
const data = reactive({
  contract: pageParam ? JSON.parse(JSON.stringify(pageParam)) : { 'contractType': 'sale' },
  file: {}
})

data.file.qualification = util.json2Elfile(data.contract.qualification)
data.file.contractDraft = util.json2Elfile(data.contract.contractDraft)
data.file.contract = util.json2Elfile(data.contract.contract)

api.get('/backend/contract/getTypes', { params: { code: 'supplier' } }).then(res => {
  show.typeList = res.list
})

const add = () => {
  const param = JSON.parse(JSON.stringify(data.contract))
  param.qualification = util.elfile2Json(data.file.qualification)
  param.contractDraft = util.elfile2Json(data.file.contractDraft)
  param.contractDate = util.parseTime(param.contractDate, '{y}-{m}-{d}')
  api.post('/backend/contract/add', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}

const setById = () => {
  const param = { id: data.contract.id }
  param.contract = util.elfile2Json(data.file.contract)
  param.note = data.contract.note
  api.post('/backend/contract/setById', param).then(() => {
    ElMessage.success('保存成功')
    router.go(-1)
  })
}
</script>

<style lang="less">
.saleContractDetail {
  margin: auto;
  max-width: 900px;
}
</style>